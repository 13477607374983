import Vue from 'vue'
import { userSignInService,getUserWebSocket,getUserWebSocketSwitch,getUserWebSocketResult } from '@/service/user-service';
import { onDebounce } from '@/utils/tools'
import Cookies from 'js-cookie';
import { mapMutations } from 'vuex'
import { BUSINESS_CODE_MODE } from '@/service/business-enum';
import { LOGIN_CHECK_MESSAGE,LOGIN_MODE,ONLINE_STATUS } from '@/service/user-service/user-enum';
import { Message,Modal,LoadingBar, Button, Input, Icon,Checkbox } from 'view-design';
import { isMobile } from '@/utils/tools'
Vue.component('Button', Button);
Vue.component('Input', Input);
Vue.component('Icon', Icon);
Vue.component('Checkbox', Checkbox);
Vue.component('Modal', Modal);
import { systemPublishEnum,systemPublishLogo } from '@/service/system-service'
const Login = {
    name:'Login',
    data(){
        return{
            loginSpread:LOGIN_MODE.ACCOUNT_MODE,
            loginMode:LOGIN_MODE,
            systemPublishMenu:systemPublishEnum, //配置演示状态枚举
            systemPublishLogo:systemPublishLogo, //配置演示状态
            loadingStatus:false, //loading 当前状态 请求发起时设为true 结束设为false ps:等待效果防止用户点击过快
            resultStatus:false,
            resultLoading:false,
            userDataObj:{},
            userInfo:{
                userName:'',
                password:'',
                vCode:'1234'
            },
        }
    },
    mounted() {
        window.addEventListener('keydown',this.onKeyDown)
    },
    methods:{
        ...mapMutations('userCenter',['SET_USER_INFO']),
        /**
         * 获取键盘Enter值 调用登录
         * @param e
         */
        onKeyDown(e) {
            if(e.keyCode === 13){
                this.onSignInCheck()
            }
        },
        /**
         *  校验方法 通过之后开启防抖函数 调用登录方法
         *  @param account && password
         */
        onSignInCheck() {
            if(this.userInfo.userName === '' || this.userInfo.password === ''){
                Message.error(LOGIN_CHECK_MESSAGE.USERNAME_OR_PASSWORD_FREE_ERROR)
            }else{
                onDebounce(this.onSignIn(),1500)
            }
        },
        onRegister() {
            Message.warning('此功能暂未开放！');
        },
        onResultCancel() {
            LoadingBar.finish()
            this.resultLoading = false
            this.loadingStatus = false
            this.resultStatus = false
        },
        onResultOk() {
            this.resultLoading = true
            this.onUserWebSocketSwitch(this.userDataObj).then()
        },
        /**
         * 登录方法
         * @returns {Promise<void>}
         */
        async onSignIn() {
            if(this.loginSpread===LOGIN_MODE.VERIFICATION_CODE_MODE){
                Message.warning('此功能暂未开放！');
            }else{
                try {
                    LoadingBar.start()
                    this.loadingStatus = true
                    let obj = {"loginAccount": this.userInfo.userName,"password":this.userInfo.password,"terminal":isMobile()}
                    const userData = await userSignInService(obj)
                    switch (userData.code){
                        case BUSINESS_CODE_MODE.SUCCESS:  //业务成功
                            this.onUserWebSocket(userData).then()
                            break;
                        case BUSINESS_CODE_MODE.USERNAME_OR_PASSWORD_FREE_ERROR: //用户名或密码错误
                            LoadingBar.error();
                            Message.error(LOGIN_CHECK_MESSAGE.USERNAME_OR_PASSWORD_FREE_ERROR);
                            this.loadingStatus = false
                            break;
                        case BUSINESS_CODE_MODE.USER_NOT_AUTHORIZED_TO_LOGIN:
                            LoadingBar.error();
                            Message.error('用户无权登录！');
                            this.loadingStatus = false
                    }
                }catch (error){
                    LoadingBar.error()
                    console.log(error)
                    this.loadingStatus = false
                }
            }
        },
        /**
         * 查询用户获取websocket状态
         * @returns {Promise<void>}
         */
        async onUserWebSocket(userData) {
            try {
                let params = {
                    token : userData.data.token
                }
                const {code,data} = await getUserWebSocket(params)
                if(code === 'SUCCESS'){
                    if(data.state === ONLINE_STATUS.OFFLINE){
                        LoadingBar.finish()
                        // Cookies.set('token',userData.data.token);
                        Cookies.set('token',userData.data.token,{expires:1});
                        // this.$store.commit('SET_USER_INFO',userData.data)
                        this.SET_USER_INFO(userData.data)
                        this.$forceUpdate()
                        Modal.remove()
                        await this.$router.push('/');
                    }else if(data.state === ONLINE_STATUS.ONLINE || data.state === ONLINE_STATUS.CALLING){
                        Modal.confirm({
                            title: '提示',
                            content: '<p>账户正在其他设备上使用</p><p>确定要切换登录设备吗？</p>',
                            onOk: () => {
                                this.onUserWebSocketSwitch(userData).then()
                            },
                            onCancel: () => {
                                LoadingBar.finish()
                                this.loadingStatus = false
                            }
                        });
                    }else if(data.state === ONLINE_STATUS.CONVERSATION){
                        this.resultStatus = true
                        this.userDataObj = {}
                        this.userDataObj = userData
                    }else if(data.state === ONLINE_STATUS.UNKNOWN){
                        Message.error('登录异常，请联系管理员！')
                        LoadingBar.finish()
                        this.loadingStatus = false
                        Modal.remove()
                    }
                }else{
                    Message.error('请求异常，请联系管理员！')
                    LoadingBar.error()
                    this.loadingStatus = false
                    Modal.remove()
                }
            }catch (error){
                this.loadingStatus = false
            }
        },
        /**
         * 发起登录切换
         * @returns {Promise<void>}
         */
        async onUserWebSocketSwitch(userData) {
            try {
                let params = {
                    token : userData.data.token
                }
                const {code,data} = await getUserWebSocketSwitch(params)
                if(code === 'SUCCESS'){
                    if(data.state === 1){
                        LoadingBar.finish()
                        // Cookies.set('token',userData.data.token);
                        Cookies.set('token',userData.data.token,{expires:1});
                        // this.$store.commit('SET_USER_INFO',userData.data)
                        this.SET_USER_INFO(userData.data)
                        this.$forceUpdate()
                        this.resultStatus = false
                        this.resultLoading = false
                        Modal.remove()
                        await this.$router.push('/');
                    }else if(data.state === 2){
                        Message.warning({duration:3,content:'此帐号在其他端登录，对方拒绝您的登录请求！'})
                        LoadingBar.error()
                        this.loadingStatus = false
                        this.resultLoading = false
                        this.resultStatus = false
                        Modal.remove()
                    }else if(data.state === -1){
                        Message.error('推送失败，请重试！')
                        LoadingBar.error()
                        this.loadingStatus = false
                        this.resultLoading = false
                        this.resultStatus = false
                        Modal.remove()
                    }else if(data.state === 0){
                        this.onUserWebSocketResult(userData,data).then()
                    }
                }else{
                    Message.error('请求异常，请联系管理员！')
                    this.resultLoading = false
                    this.loadingStatus = false
                    this.resultStatus = false
                    Modal.remove()
                }
            }catch (error){
                this.loadingStatus = false
            }
        },
        /**
         * 查询登录结果
         * @returns {Promise<void>}
         */
        async onUserWebSocketResult(userData,switchData) {
            try {
                let params = {
                    token : userData.data.token,
                    tag:switchData.tag,
                }
                const {code,data} = await getUserWebSocketResult(params)
                if(code === 'SUCCESS'){
                    if(data.state === 1){
                        LoadingBar.finish()
                        // Cookies.set('token',userData.data.token);
                        Cookies.set('token',userData.data.token,{expires:1});
                        // this.$store.commit('SET_USER_INFO',userData.data)
                        this.SET_USER_INFO(userData.data)
                        this.$forceUpdate()
                        Modal.remove()
                        await this.$router.push('/');
                    }else if(data.state === 2){
                        Message.warning({duration:3,content:'此帐号在其他端登录，对方拒绝您的登录请求！'})
                        LoadingBar.error()
                        Modal.remove()
                        this.loadingStatus = false
                        this.resultLoading = false
                        this.resultStatus = false
                    }else if(data.state === -1){
                        Message.error('推送失败，请重试！')
                        Modal.remove()
                        LoadingBar.error()
                        this.loadingStatus = false
                        this.resultLoading = false
                        this.resultStatus = false
                    }else if(data.state === 0){
                        setTimeout(()=>{
                            this.onUserWebSocketResult(userData,switchData).then()
                        },1000)
                    }
                }else{
                    Message.error('请求异常，请联系管理员！')
                    Modal.remove()
                    LoadingBar.error()
                    this.loadingStatus = false
                    this.resultLoading = false
                    this.resultStatus = false
                }
            }catch (error){
                this.loadingStatus = false
            }
        },
    },
    destroyed() {
        window.removeEventListener('keydown',this.onKeyDown,false);
    }
}
export default Login
