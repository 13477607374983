<template>
    <div class="layout-login">
        <div class="layout-login-center">
            <div class="login-box">
                 <!--s: header-->
                <div class="layout-login-header">
                    <div class="header-info" v-if="systemPublishLogo === systemPublishMenu.NORMAL">
                        <a href="javascript:" class="logo"><img src="../../assets/Login/login-logo.png"></a>
                        <ul class="header-list">
                            <li>工业解决方案引领者</li>
                            <li>工业智能服务专家</li>
                        </ul>
                    </div>
                    <div class="header-logo" v-if="systemPublishLogo === systemPublishMenu.DEMONSTRATE">
                        <h1 class="header-name">AR智能服务平台</h1>
                        <span class="header-text">工业解决方案引领者 | 工业智能服务专家</span>
                    </div>
                </div>
                 <!--d: header -->
                 <!--s: sign region-->
                 <div class="layout-sign">
                     <div class="sign-title" v-if="systemPublishLogo === systemPublishMenu.NORMAL">
                         <h2>登录</h2>
                     </div>
                     <div class="sign-top" v-if="systemPublishLogo === systemPublishMenu.DEMONSTRATE">
                         <h2>用户登录</h2>
                     </div>
                     <div class="sign-box">
                         <div class="sign-label">
                             <Input v-model="userInfo.userName" type="tel" prefix="ios-contact-outline" size="large" maxlength="11" placeholder="请输入帐号" />
                         </div>
                         <div class="sign-label">
                             <Input v-model="userInfo.password" prefix="ios-lock-outline" size="large" type="password" password placeholder="请输入密码" />
                         </div>
                         <div class="sign-label label-top">
                             <div class="sign-remember">
                                 <Checkbox>记住密码</Checkbox>
                             </div>
<!--                             <a href="javascript:" class="sign-forget" @click="onRegister">忘记密码?</a>-->
                         </div>
                         <div class="sign-label label-top-btn">
                             <Button type="primary" long :loading="loadingStatus" @click="onSignInCheck">
                                 <span v-if="!loadingStatus">登录</span>
                                 <span v-else>登录</span>
                             </Button>
                         </div>
<!--                         <div class="register-box">-->
<!--                             <a href="javascript:" @click="onRegister">注册账户</a>-->
<!--                         </div>-->
                     </div>
                 </div>
                 <!--d: sign region-->
            </div>
            <div class="footer">{{ systemPublishLogo === systemPublishMenu.NORMAL?'灵云':'' }}V1.0 Copyright ©2019-2023</div>
        </div>
        <Modal
            v-model="resultStatus"
            :width="416"
            :closable="false"
            :mask-closable="false"
            footer-hide>
            <div class="result-modal-confirm">
                <div class="result-modal-confirm-head">
                    <div class="head-icon"><Icon type="ios-help" size="28"></Icon></div>
                    <div class="head-modal-confirm-head-title">提示</div>
                </div>
                <div class="result-modal-confirm-body">
                    <p>账户正在其他设备进行会话，需等待验证，确定要</p>
                    <p>切换登录设备吗？</p>
                </div>
                <div class="result-modal-confirm-footer">
                    <Button type="text" v-if="!resultLoading" @click="onResultCancel">取消</Button>
                    <Button type="primary" :loading="resultLoading" @click="onResultOk">确定</Button>
                </div>
            </div>
        </Modal>
    </div>
</template>
<script>
import Login from './login'
export default Login
</script>
<style lang="less">
    @import "login";
</style>
